import React from 'react';
import { ReactComponent as IconWhatsApp } from 'assets/img/icons/whatsapp-icon.svg';

interface Props {
  children?: any;
}

const Layout = (props: Props) => {
  return (
    <div className="imd-layout">
      {props.children}
      <a
        href="https://api.whatsapp.com/send?phone=51924770892"
        target="_blank"
        rel="noopener noreferrer"
        className="imd-fixed-whats-app"
        title="Enviar Mensaje a Whatsapp"
      >
        <IconWhatsApp />
      </a>
    </div>
  );
};

export default Layout;
