import React from 'react';
import Layout from './templates/Layout';
import Header from './organisms/Header';
import Content from './organisms/Content';
import Footer from './organisms/Footer';
import Routes from './routes/index';

function App() {
  return (
    <Layout>
      <Header />
      <Content>
        <Routes />
      </Content>
      <Footer />
    </Layout>
  );
}

export default App;
