import React, { Component } from 'react';

interface Props {
  children?: any;
}
interface State {}

export default class Content extends Component<Props, State> {
  state = {};

  render() {
    return <div className="imd-main-content">{this.props.children}</div>;
  }
}
