import React, { Component } from 'react';

interface Props {
  children?: any;
  className?: string;
}
interface State {}

export default class Menu extends Component<Props, State> {
  state = {};

  render() {
    return <ul className={this.props.className}>{this.props.children}</ul>;
  }
}
