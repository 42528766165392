import React from 'react';

interface Props {
  children?: any;
  className?: string;
}

const Col = (props: Props) => {
  return <div className={props.className}>{props.children}</div>;
};

export default Col;
