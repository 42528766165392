import React from 'react';

interface Props {
  description: React.ReactNode;
  classNames?: string;
}

const Paragraph = ({ description, classNames }: Props) => {
  return <p className={`imd-parrafo ${classNames?classNames:''}`}>{description}</p>;
};

export default Paragraph;
