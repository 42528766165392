import React, { Fragment, useState, useRef } from 'react';
import swal from 'sweetalert';
import axios from 'axios';
import Section from 'molecules/Section';
import Col from 'atoms/Col';
import Title from 'atoms/Title';
import Paragraph from 'atoms/Paragraph';
import Image from 'atoms/Image';
import { ReactComponent as ContactImage } from 'assets/img/contact.svg';
import { ReactComponent as IconMessenger } from 'assets/img/icons/facebook-messenger.svg';
import { ReactComponent as IconWhatsApp } from 'assets/img/icons/whatsapp-icon.svg';

interface Props {}

const Contact = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const formControl = useRef<HTMLFormElement>(null);

  const handleOnSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.target;
    if (form !== null) {
      try {
        const formData = new FormData();
        setLoading(true);
        formData.append('name', `${form.fullName.value}, ${form.lastName.value}`);
        formData.append('email', form.email.value);
        formData.append('phone', form.phone.value);
        formData.append('company', form.company.value);
        formData.append('message', form.message.value);
        const response = await axios.post(`${process.env.REACT_APP_API}/server.php`, formData);
        const data = await response.data;
        if (data && data.message === 'ok') {
          setLoading(false);
          swal('Bien!', 'Gracias, nos pondremos en contacto lo más pronto posible.', 'success');
          formControl && formControl.current && formControl.current.reset();
        }
      } catch (error) {
        setLoading(false);
        swal('Error!', 'Por favor, refresca la pagina e intentalo nuevamente!', 'error');
      }
    }
  };
  return (
    <Fragment>
      <Section className="imd-column-2 imd-bg-tertiary">
        <Col className="imd-col-description imd-contact-description">
          <Image className="imd-image-box">
            <ContactImage />
          </Image>
        </Col>
        <Col className="imd-col-image">
          <div className="imd-contact-form-container">
            <Title text="¿Quieres conocer iMedical?" className="imd-text-primary" />
            <Paragraph description="Chatea con nosotros en vivo" />
            <div className="imd-redes-contact">
              <div className="item-redes-contact">
                <a href="www.facebook.com">
                  <IconMessenger />
                </a>
              </div>
              <div className="item-redes-contact ">
                <a
                  href="https://api.whatsapp.com/send?phone=51924770892"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconWhatsApp />
                </a>
              </div>
            </div>
            <div className="imd-divier-o">
              <hr />
              <span>o</span>
              <hr />
            </div>
            <div className="item-form-contact">
              <Paragraph description="Déjanos tus datos y te enviamos la información a tu email." />
              <form onSubmit={handleOnSubmit} ref={formControl}>
                <div className="form-control">
                  <label>Nombre*</label>
                  <input type="text" name="fullName" required />
                </div>
                <div className="form-control">
                  <label>Apellidos*</label>
                  <input type="text" name="lastName" required />
                </div>
                <div className="form-control">
                  <label>Teléfono*</label>
                  <input type="text" maxLength={12} name="phone" required />
                </div>
                <div className="form-control">
                  <label>Email contacto*</label>
                  <input type="email" name="email" required />
                </div>
                <div className="form-control">
                  <label>Empresa</label>
                  <input type="text" name="company" />
                </div>
                <div className="form-control">
                  <label>
                    ¿Algo más que debamos saber?
                    <small>
                      Cuéntanos un poco de las necesidades que tienes hoy qué te gustaría resolver
                      usando iMedical
                    </small>
                  </label>
                  <textarea name="message" />
                </div>
                <div className="form-control">
                  {loading ? (
                    <button type="button" disabled={true} className="imd-btn-primary imd-m-0">
                      Enviando Solicitud...
                    </button>
                  ) : (
                    <button type="submit" className="imd-btn-primary imd-m-0">
                      SOLICITAR INFORMACIÓN
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Section>
    </Fragment>
  );
};

export default Contact;
