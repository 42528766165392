import React from 'react';

interface Props {
  className?: string;
  children?: any;
}

const Image = (props: Props) => {
  return <div className={props.className}>{props.children}</div>;
};

export default Image;
