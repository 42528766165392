import React from 'react';
import IconFont from './iconfont';

interface IconProps {
  name?: any;
  size?: number;
  color?: string;
}

const Icon = (props: IconProps) => {
  return <IconFont name={props.name} size={props.size} color={props.color} />;
};

export default Icon;
