import React, { Component } from 'react';
import Menu from 'molecules/Menu';
import logo from 'assets/img/logo.png';
import MenuItem from 'molecules/MenuItem';
import Icon from 'atoms/Icon';
import { Link } from 'react-router-dom';

interface Props {}
interface State {}

export default class Header extends Component<Props, State> {
  state = {
    visible: false,
  };

  toggleMenu = () => {
    this.setState({ visible: !this.state.visible });
  };

  render() {
    return (
      <nav className="imd-header">
        <div className="imd-header-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="imd-movile-menu-icon">
          <label htmlFor="menu-toggle">
            <Icon name={this.state.visible ? 'close' : 'menu'} size={30} color="#0a1b43" />
          </label>
          <input
            type="checkbox"
            id="menu-toggle"
            checked={this.state.visible}
            onChange={this.toggleMenu}
          />
          <Menu className="imd-menu-movile">
            <MenuItem url="#caracteristicas" onClick={this.toggleMenu} className="imd-link">
              Características
            </MenuItem>
            <MenuItem url="#precios" onClick={this.toggleMenu} className="imd-link">
              Precio
            </MenuItem>
            <MenuItem url="#beneficios" onClick={this.toggleMenu} className="imd-link">
              Beneficios
            </MenuItem>
            <MenuItem url="/solicitar-prueba" onClick={this.toggleMenu} className="imd-btn-primary">
              SOLICITAR PRUEBA
            </MenuItem>
          </Menu>
        </div>
        <Menu className="imd-menu">
          <MenuItem url="#caracteristicas" className="imd-link">
            Características
          </MenuItem>
          <MenuItem url="#precios" className="imd-link">
            Precio
          </MenuItem>
          <MenuItem url="#beneficios" className="imd-link">
            Beneficios
          </MenuItem>
          <MenuItem url="/solicitar-prueba" className="imd-btn-primary">
            SOLICITAR PRUEBA
          </MenuItem>
        </Menu>
      </nav>
    );
  }
}
