import React from 'react';
import { ReactComponent as ErrorImage } from 'assets/img/Error.svg';
import { Link } from 'react-router-dom';

interface Props {}

const Error = (props: Props) => {
  return (
    <div className="imd-error-page">
      <ErrorImage />
      <p className="imd-parrafo">No encontramos lo que buscabas.</p>
      <Link to="/" className="imd-btn-primary">
        IR AL INICIO
      </Link>
    </div>
  );
};

export default Error;
