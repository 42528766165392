import React, { Component, Fragment } from 'react';
import { ReactComponent as CoverPage } from 'assets/img/portada.svg';
import { ReactComponent as CalendarImage } from 'assets/img/video-section.svg';
import { ReactComponent as PlayButton } from 'assets/img/play-button.svg';
import ScrollableAnchor from 'react-scrollable-anchor';
import Title from 'atoms/Title';
import Paragraph from 'atoms/Paragraph';
import Image from 'atoms/Image';
import Section from 'molecules/Section';
import { data } from 'data';
import Col from 'atoms/Col';
import CircleCard from 'atoms/CircleCard';
import Icon from 'atoms/Icon';
import { Link } from 'react-router-dom';

interface Props {}
interface State {}

export default class Home extends Component<Props, State> {
  handleOnChange = (checked: boolean, event: any) => {};
  renderSubItems = (data: any) => {
    const Items = data.map((item: any, index: number) => (
      <div className="imd-price-table-row imd-border-bottom" key={`${index}_SUB_ITEM`}>
        <div className="imd-column-table imd-text-left imd-fw-medium">{item.description}</div>
        <div className="imd-column-table imd-text-center">
          {item.basic === true ? (
            <Icon name="checked" color="#00EFC9" size={20} />
          ) : item.basic === false ? (
            <Icon name="checked" color="#FDCA14" size={20} />
          ) : item.basic === null ? (
            <Icon name="close" color="#ff1500" size={20} />
          ) : (
            item.basic
          )}
        </div>
        <div className="imd-column-table imd-text-center">
          {item.standar === true ? (
            <Icon name="checked" color="#00EFC9" size={20} />
          ) : item.standar === false ? (
            <Icon name="checked" color="#FDCA14" size={20} />
          ) : item.standar === null ? (
            <Icon name="close" color="#ff1500" size={20} />
          ) : (
            item.standar
          )}
        </div>
        <div className="imd-column-table imd-text-center">
          {item.advanced === true ? (
            <Icon name="checked" color="#00EFC9" size={20} />
          ) : item.advanced === false ? (
            <Icon name="checked" color="#FDCA14" size={20} />
          ) : item.advanced === null ? (
            <Icon name="close" color="#ff1500" size={20} />
          ) : (
            item.advanced
          )}
        </div>
      </div>
    ));

    return Items;
  };
  renderSubItemsMoviel = (data: any, plan: string) => {
    const Items = data.map((item: any, index: number) => (
      <Fragment key={`${index}_ITEMS`}>
        <div className="imd-card-body-item">{item.description}</div>
        <div className="imd-card-body-item-validate">
          {item[plan] === true ? (
            <Icon name="checked" color="#00EFC9" size={20} />
          ) : item[plan] === false ? (
            <Icon name="checked" color="#FDCA14" size={20} />
          ) : item[plan] === null ? (
            <Icon name="close" color="#ff1500" size={20} />
          ) : (
            item[plan]
          )}
        </div>
      </Fragment>
    ));
    return Items;
  };
  render() {
    const { items } = data.home.benefits;
    const { solutions } = data.home.characteristics;
    const { listCharacter } = data.home.prices.table.characteristics;
    return (
      <Fragment>
        <Section className="imd-column-1">
          <Title text={data.home.coverPage.title} className="imd-text-primary imd-text-center" />
          <Paragraph description={data.home.coverPage.paragraph} classNames="imd-text-center" />
          <Link to="/solicitar-prueba" className="imd-btn-primary">
            SOLICITAR PRUEBA
          </Link>
          <Image className="imd-image-box">
            <CoverPage />
          </Image>
        </Section>
        <Section className="imd-column-2 imd-bg-gey-1">
          <Col className="imd-col-description">
            <Title text={data.home.videoSecction.title} className="imd-text-primary" />
            <Paragraph description={data.home.videoSecction.paragraph} />
            <div className="imd-play-button">
              <PlayButton /> <span>VER VIDEO</span>
            </div>
          </Col>
          <Col className="imd-col-image">
            <Image className="imd-image-box">
              <CalendarImage />
            </Image>
          </Col>
        </Section>

        <ScrollableAnchor id="caracteristicas">
          <Section className="imd-column-1 imd-mt-2 imd-mb-2">
            <Title
              text={data.home.characteristics.title}
              className="imd-text-primary imd-separator imd-fs-xl imd-text-color imd-text-center"
            />
            <Paragraph description={data.home.characteristics.paragraph} classNames="imd-text-center" />
          </Section>
        </ScrollableAnchor>
        {solutions &&
          solutions.map((solution, index) => {
            return (
              <Section className="imd-column-even-odd" key={index}>
                {index % 2 === 0 ? (
                  <Fragment>
                    <Col className="imd-col-image">
                      <Image className="imd-image-box">{solution.image}</Image>
                    </Col>
                    <Col className="imd-col-description">
                      <Title text={solution.title} className="imd-text-primary" />
                      <Paragraph description={solution.description} />
                      <ul className="imd-list-solution">
                        {Array.isArray(solution.features) &&
                          solution.features.map((ft, idx) => (
                            <li key={idx}>
                              <Icon name="checked" color="#5381fb" />
                              <span>{ft}</span>
                            </li>
                          ))}
                      </ul>
                    </Col>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Col className="imd-col-description">
                      <Title text={solution.title} className="imd-text-primary" />
                      <Paragraph description={solution.description} />
                      <ul className="imd-list-solution">
                        {Array.isArray(solution.features) &&
                          solution.features.map((ft, idx) => (
                            <li key={idx}>
                              <Icon name="checked" color="#5381fb" />
                              <span>{ft}</span>
                            </li>
                          ))}
                      </ul>
                    </Col>
                    <Col className="imd-col-image">
                      <Image className="imd-image-box">{solution.image}</Image>
                    </Col>
                  </Fragment>
                )}
              </Section>
            );
          })}
        <ScrollableAnchor id="beneficios">
          <Section className="imd-column-1 imd-bg-secondary">
            <Title text={data.home.benefits.title} className="imd-text-white imd-text-center" />
            <div className="imd-card-circle-container">
              {items &&
                items.map((item, index) => (
                  <CircleCard
                    key={index}
                    icon={item.icon}
                    title={item.title}
                    description={item.description}
                  />
                ))}
            </div>
          </Section>
        </ScrollableAnchor>
        <ScrollableAnchor id="precios">
          <Section className="imd-column-price">
            <Title
              text={data.home.prices.title}
              className="imd-text-primary imd-text-center imd-fs-xl"
            />
            <Paragraph description={data.home.prices.description} classNames="imd-text-center" />

            {/* price laptop, tableta de alta definicio y pc */}
            <div className="imd-price-table-container imd-stycky-header ">
              <div className="imd-price-table-row imd-prices-table-title ">
                <div className="imd-title-table">
                  {data.home.prices.table.characteristics.title}
                </div>
                <div className="imd-title-table imd-text-center">
                  {data.home.prices.table.plans.basic.title}
                </div>
                <div className="imd-title-table imd-text-center">
                  {data.home.prices.table.plans.standar.title}
                </div>
                <div className="imd-title-table imd-text-center">
                  {data.home.prices.table.plans.advanced.title}
                </div>
              </div>
              <div className="imd-price-table-row imd-prices-table-prices ">
                <div className="imd-title-price">
                  {data.home.prices.table.characteristics.monthlypayment}
                </div>
                <div className="imd-title-price imd-text-center">
                  {data.home.prices.table.plans.basic.price}
                </div>
                <div className="imd-title-price imd-text-center">
                  {data.home.prices.table.plans.standar.price}
                </div>
                <div className="imd-title-price imd-text-center">
                  {data.home.prices.table.plans.advanced.price}
                </div>
              </div>
              <div className="imd-price-table-row imd-prices-table-offer ">
                <div className="imd-title-offer">
                  {data.home.prices.table.characteristics.specialOffer}
                </div>
                <div className="imd-title-offer imd-text-center">
                  {data.home.prices.table.plans.basic.offer}
                </div>
                <div className="imd-title-offer imd-text-center">
                  {data.home.prices.table.plans.standar.offer}
                </div>
                <div className="imd-title-offer imd-text-center">
                  {data.home.prices.table.plans.advanced.offer}
                </div>
              </div>
            </div>
            <div className="imd-price-table-container imd-pt-0 imd-mt-0 ">
              {listCharacter &&
                listCharacter.map((list, index) => {
                  return list.children?.length > 0 ? (
                    <Fragment key={`${index}_ITEMS`}>
                      <div
                        className="imd-price-table-row imd-border-bottom imd-bg-opacity"
                        key={`${index}_TITLE`}
                      >
                        <span className="imd-text-uppercase imd-text-info">{list.title}</span>
                      </div>
                      {this.renderSubItems(list.children)}
                    </Fragment>
                  ) : (
                    <div className="imd-price-table-row imd-border-bottom" key={`${index}_ITEMS`}>
                      <div className="imd-column-table imd-text-left imd-fw-medium">
                        {list.title}
                      </div>
                      <div className="imd-column-table imd-text-center">
                        {list.basic === true ? (
                          <Icon name="checked" color="#00EFC9" size={20} />
                        ) : list.basic === false ? (
                          <Icon name="checked" color="#FDCA14" size={20} />
                        ) : list.basic === null ? (
                          <Icon name="close" color="#ff1500" size={20} />
                        ) : (
                          list.basic
                        )}
                      </div>
                      <div className="imd-column-table imd-text-center">
                        {list.standar === true ? (
                          <Icon name="checked" color="#00EFC9" size={20} />
                        ) : list.standar === false ? (
                          <Icon name="checked" color="#FDCA14" size={20} />
                        ) : list.standar === null ? (
                          <Icon name="close" color="#ff1500" size={20} />
                        ) : (
                          list.standar
                        )}
                      </div>
                      <div className="imd-column-table imd-text-center">
                        {list.advanced === true ? (
                          <Icon name="checked" color="#00EFC9" size={20} />
                        ) : list.advanced === false ? (
                          <Icon name="checked" color="#FDCA14" size={20} />
                        ) : list.advanced === null ? (
                          <Icon name="close" color="#ff1500" size={20} />
                        ) : (
                          list.advanced
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>

            {/* price movile */}
            <div className="imd-card-table-movile">
              <div className="imd-card-table-header">
                <div className="imd-title-prices"> {data.home.prices.table.plans.basic.title}</div>
                <div className="imd-title-price">{data.home.prices.table.plans.basic.price}</div>
                <div className="imd-title-offer imd-text-center">
                  {data.home.prices.table.plans.basic.offer}
                </div>
                <small className="imd-text-grey imd-fw-ligth imd-fs-sm">
                  {data.home.prices.table.legend.important}
                </small>
              </div>
              <div className="imd-card-table-body">
                {listCharacter &&
                  listCharacter.map((list, index) => {
                    return list.children?.length > 0 ? (
                      <Fragment key={`${index}_ITEMS`}>
                        <div className="imd-card-body-item-title imd-bg-opacity">
                          <span className="imd-text-uppercase imd-text-info">{list.title}</span>
                        </div>
                        <div className="imd-card-body-item-validate imd-bg-opacity" />
                        {this.renderSubItemsMoviel(list.children, 'basic')}
                      </Fragment>
                    ) : (
                      <Fragment key={`${index}_ITEMS`}>
                        <div className="imd-card-body-item">{list.title}</div>
                        <div className="imd-card-body-item-validate">
                          {list.basic === true ? (
                            <Icon name="checked" color="#00EFC9" size={20} />
                          ) : list.basic === false ? (
                            <Icon name="checked" color="#FDCA14" size={20} />
                          ) : list.basic === null ? (
                            <Icon name="close" color="#ff1500" size={20} />
                          ) : (
                            list.basic
                          )}
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
              <div className="imd-text-center imd-pt-2">
                <Link to="/solicitar-prueba" className="imd-btn-primary">
                  SOLICITAR PRUEBA
                </Link>
              </div>
            </div>

            <div className="imd-card-table-movile">
              <div className="imd-card-table-header">
                <div className="imd-title-prices">
                  {' '}
                  {data.home.prices.table.plans.standar.title}
                </div>
                <div className="imd-title-price">{data.home.prices.table.plans.standar.price}</div>
                <div className="imd-title-offer imd-text-center">
                  {data.home.prices.table.plans.standar.offer}
                </div>
                <small className="imd-text-grey imd-fw-ligth imd-fs-sm">
                  {data.home.prices.table.legend.important}
                </small>
              </div>
              <div className="imd-card-table-body">
                {listCharacter &&
                  listCharacter.map((list, index) => {
                    return list.children?.length > 0 ? (
                      <Fragment key={`${index}_ITEMS`}>
                        <div className="imd-card-body-item-title imd-bg-opacity">
                          <span className="imd-text-uppercase imd-text-info">{list.title}</span>
                        </div>
                        <div className="imd-card-body-item-validate imd-bg-opacity" />
                        {this.renderSubItemsMoviel(list.children, 'standar')}
                      </Fragment>
                    ) : (
                      <Fragment key={`${index}_ITEMS`}>
                        <div className="imd-card-body-item">{list.title}</div>
                        <div className="imd-card-body-item-validate">
                          {list.standar === true ? (
                            <Icon name="checked" color="#00EFC9" size={20} />
                          ) : list.standar === false ? (
                            <Icon name="checked" color="#FDCA14" size={20} />
                          ) : list.standar === null ? (
                            <Icon name="close" color="#ff1500" size={20} />
                          ) : (
                            list.standar
                          )}
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
              <div className="imd-text-center imd-pt-2">
                <Link to="/solicitar-prueba" className="imd-btn-primary">
                  SOLICITAR PRUEBA
                </Link>
              </div>
            </div>

            <div className="imd-card-table-movile">
              <div className="imd-card-table-header">
                <div className="imd-title-prices">
                  {' '}
                  {data.home.prices.table.plans.advanced.title}
                </div>
                <div className="imd-title-price">{data.home.prices.table.plans.advanced.price}</div>
                <div className="imd-title-offer imd-text-center">
                  {data.home.prices.table.plans.advanced.offer}
                </div>
              </div>
              <div className="imd-card-table-body">
                {listCharacter &&
                  listCharacter.map((list, index) => {
                    return list.children?.length > 0 ? (
                      <Fragment key={`${index}_ITEMS`}>
                        <div className="imd-card-body-item-title imd-bg-opacity">
                          <span className="imd-text-uppercase imd-text-info">{list.title}</span>
                        </div>
                        <div className="imd-card-body-item-validate imd-bg-opacity" />
                        {this.renderSubItemsMoviel(list.children, 'advanced')}
                      </Fragment>
                    ) : (
                      <Fragment key={`${index}_ITEMS`}>
                        <div className="imd-card-body-item">{list.title}</div>
                        <div className="imd-card-body-item-validate">
                          {list.advanced === true ? (
                            <Icon name="checked" color="#00EFC9" size={20} />
                          ) : list.advanced === false ? (
                            <Icon name="checked" color="#FDCA14" size={20} />
                          ) : list.advanced === null ? (
                            <Icon name="close" color="#ff1500" size={20} />
                          ) : (
                            list.advanced
                          )}
                        </div>
                      </Fragment>
                    );
                  })}
              </div>
              <div className="imd-text-center imd-pt-2">
                <Link to="/solicitar-prueba" className="imd-btn-primary">
                  SOLICITAR PRUEBA
                </Link>
              </div>
            </div>

            <div className="imd-legend-table-price">
              <small className="imd-text-info imd-pl-1 imd-fw-ligth imd-fs-sm imd-d-none-sm">
                {data.home.prices.table.legend.important}
              </small>
              <span>
                <Icon name="checked" color="#00EFC9" size={20} /> Inluye
              </span>
              <span>
                <Icon name="close" color="#ff1500" size={20} /> No Inluye
              </span>
              <span>
                <Icon name="checked" color="#FDCA14" size={20} /> Opcional (Costo adicional)
              </span>
            </div>
          </Section>
        </ScrollableAnchor>
      </Fragment>
    );
  }
}
