import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from 'pages/Home';
import Error from 'pages/Error';
import Contact from 'pages/Contact';

interface Props {}

const Routes = (props: Props) => {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/solicitar-prueba">
        <Contact />
      </Route>
      <Route path="*">
        <Error />
      </Route>
    </Switch>
  );
};

export default Routes;
