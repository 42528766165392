import React, { Component } from 'react';
import { ReactComponent as IconMessenger } from 'assets/img/icons/facebook-messenger.svg';
import { ReactComponent as IconInstagram } from 'assets/img/icons/instagram.svg';
import { ReactComponent as IconWhatsApp } from 'assets/img/icons/whatsapp-icon.svg';

interface Props {
  children?: any;
}
interface State {}

export default class Footer extends Component<Props, State> {
  state = {};

  render() {
    return (
      <div className="imd-footer">
        <div className="imd-footer-item">
          <img src={require('assets/img/logo.png')} alt="iMedical" />
          <p>Desarrollado por:</p>
          <small><a href="https://amazontic.pe">AmazonTIC</a></small>
          <small>&copy; 2020 All Rights Reserved.</small>
        </div>
        <div className="imd-footer-item">
          <h1>Contacto</h1>
          <ul className="imd-footer-list">
            <li>
              <a href="mailto:contactanos@imedical.pe" target="_blank" rel="noopener noreferrer">
                contacto@imedical.pe
              </a>
            </li>
            <li>
              <a href="mailto:soporte@imedical.pe" target="_blank" rel="noopener noreferrer">
                soporte@imedical.pe
              </a>
            </li>
            <li>
              <a href="tel:+51 924770892" target="_blank" rel="noopener noreferrer">
                T: +51 924770892
              </a>
            </li>
          </ul>
          <div className="imd-footer-redes">
            <div className="item-footer-rede">
              <a href="https://web.facebook.com/iMedicalPeru">
                <IconMessenger />
              </a>
            </div>
            <div className="item-footer-rede">
              <a href="https://www.instagram.com/imedicalperu/">
                <IconInstagram />
              </a>
            </div>
            <div className="item-footer-rede">
              <a
                href="https://api.whatsapp.com/send?phone=51924770892"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconWhatsApp />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
