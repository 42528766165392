import React from 'react';

interface Props {
  icon?: React.ReactNode;
  title?: any;
  description?: any;
}

const CircleCard = (props: Props) => {
  return (
    <div className="imd-card-circle">
      <div className="imd-card-circle-image">{props.icon}</div>
      <h1 className="imd-card-circle-title">{props.title}</h1>
      <div className="imd-card-circle-description">{props.description}</div>
    </div>
  );
};

export default CircleCard;
