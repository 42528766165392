/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, DOMAttributes, FunctionComponent } from 'react';
import IconChecked from './IconChecked';
import IconClose from './IconClose';
import IconMenu from './IconMenu';
import IconClock from './IconClock';
import IconPlay from './IconPlay';
import IconGps from './IconGps';
import IconPhone from './IconPhone';
import IconPadlock from './IconPadlock';
import IconEnvelope from './IconEnvelope';

export type IconNames = 'checked' | 'close' | 'menu' | 'clock' | 'play' | 'gps' | 'phone' | 'padlock' | 'envelope';

interface Props extends DOMAttributes<SVGElement> {
  name: IconNames;
  size?: number;
  color?: string | string[];
  style?: CSSProperties;
  className?: string;
}

const IconFont: FunctionComponent<Props> = ({ name, ...rest }) => {
  switch (name) {
    case 'checked':
      return <IconChecked {...rest} />;
    case 'close':
      return <IconClose {...rest} />;
    case 'menu':
      return <IconMenu {...rest} />;
    case 'clock':
      return <IconClock {...rest} />;
    case 'play':
      return <IconPlay {...rest} />;
    case 'gps':
      return <IconGps {...rest} />;
    case 'phone':
      return <IconPhone {...rest} />;
    case 'padlock':
      return <IconPadlock {...rest} />;
    case 'envelope':
      return <IconEnvelope {...rest} />;

  }

  return null;
};

export default IconFont;
