import React from 'react';
import Icon from 'atoms/Icon';

interface MenuItemProps {
  icon?: string;
  url?: string;
  className?: string;
  children?: any;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  className,
  url,
  icon,
  children,
  onClick,
}) => {
  return (
    <li className="imd-menu-item">
      <a href={url} onClick={onClick} className={className}>
        {icon !== undefined ? <Icon name={icon} /> : null}
        {children}
      </a>
    </li>
  );
};
MenuItem.defaultProps = {
  className: 'imd-link',
};
export default MenuItem;
