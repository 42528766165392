import * as React from 'react';

export interface SectionProps {
  className?: string;
  children?: any;
}

export default class Section extends React.Component<SectionProps> {
  static defaultProps: SectionProps = {
    className: 'gx-col-1',
  };
  public render() {
    const { className, children } = this.props;
    return <section className={className}>{children}</section>;
  }
}
