import React from 'react';
import { ReactComponent as UserIcon } from 'assets/img/icons/user-plus.svg';
import { ReactComponent as CalendarIcon } from 'assets/img/icons/calendar.svg';
import { ReactComponent as PayIcon } from 'assets/img/icons/hand-pay.svg';
import { ReactComponent as ReceiptIcon } from 'assets/img/icons/receipt.svg';

import { ReactComponent as ImageCita } from 'assets/img/calendar.svg';
// import { ReactComponent as ImageChat } from 'assets/img/chats.svg';
import { ReactComponent as ImageHistory } from 'assets/img/history.svg';
import { ReactComponent as ImageFactura } from 'assets/img/facturacion.svg';
import { ReactComponent as ImageReporte } from 'assets/img/reporte.svg';
import { ReactComponent as ImageInventario } from 'assets/img/inventario.svg';
import { ReactComponent as ImageCrm } from 'assets/img/crm.svg';
import { ReactComponent as ImageFarmacia } from 'assets/img/farmacia.svg';

export const data = {
  home: {
    coverPage: {
      title: 'La plataforma más potente para la Gestión de Clínicas y Consultorios en la nube',
      paragraph:
        'Gestión de pacientes, agenda médica, historias clínicas, control de pagos, facturación, control de inventario, fidelización de pacientes, etc.',
    },
    videoSecction: {
      title: 'Conoce iMedical por ti mismo',
      paragraph: 'Centraliza tu historia clínica. Solo necesita una computadora o dispositivo móvil.',
    },
    benefits: {
      title: 'Agenda citas en solo 4 clics',
      items: [
        {
          title: 'PACIENTE',
          description: 'Registra el paciente y deja que el sistema autocomplete los datos.',
          icon: <UserIcon />,
        },
        {
          title: 'CITA',
          description: 'Verifica la disponibilidad y asigna un especialista y servicio.',
          icon: <CalendarIcon />,
        },
        {
          title: 'COBRO',
          description: 'Con nuestra sugerencia de procesos, realiza el cobro en segundos y sin errores.',
          icon: <PayIcon />,
        },
        {
          title: 'FACTURACIÓN',
          description: 'Genera el comprobante del paciente o integralo con facturación electrónica.',
          icon: <ReceiptIcon />,
        },
      ],
    },
    characteristics: {
      title: 'Soluciones tecnológicas innovadoras, para el mejor control de tu Clínica o Consultorio.',
      paragraph:
      'Somos expertos en soluciones de negocios y sabemos que necesitas para tu crecimiento, con un control adecuado en los ingresos y tus gastos.',
      solutions: [
        {
          title: 'Historia Clínica',
          image: <ImageHistory />,
          description:
            'Gestiona Digitalmente la historia clínica de tus pacientes sin sacrificar tiempo al momento de la consulta.',
          features: ['Historias digitales', 'Antecedentes', 'Control de signos vitales', 'Historia de consultas', 'Datos de afiliación', 'Recetas por e-mail'],
        },
        {
          title: 'Cita',
          image: <ImageCita />,
          description:
            'Planifica adecuadamente la atención de los pacientes y que nunca se crucen tus citas',
          features: ['Multiples agendas', 'Citas en línea', 'Recordatorios para el médico y paciente', 'Integración inmediata con el pago'],
        },
        {
          title: 'Facturación',
          image: <ImageFactura />,
          description:
            'Ten el control completo de los pagos de los servicios y/o productos que ofreces. Controla los pagos con las atenciones',
          features: ['Generación de facturas, boletas, tickets y notas de crédito', 'Integración con Facturación Electrónica', 'Anulaciones', 'Integrado con la consulta'],
        },
        {
          title: 'Inventario',
          image: <ImageInventario />,
          description:
            'Lleva el control de los productos de botica o farmacia y obten en pocos clicks el valor de tu mercadería',
          features: ['Control de lotes', 'Almacenes', 'Ingresos por compras', 'Salidas miscelaneas', 'Integridad de stock'],
        },
        {
          title: 'Farmacia',
          image: <ImageFarmacia />,
          description:
            'Tu farmacia integrada con tu clínica y la historia de paciente. También puedes realizar ventas individuales.',
          features: ['Listas de precios ', 'Up-selling', 'Cross-Selling', 'Fórmulas magistrales', 'Facturación adelantada de servicios'],
        },
        {
          title: 'CRM',
          image: <ImageCrm />,
          description:
            'Tus pacientes más cerca a tu Clínica, nuestra tecnología te permite fidelizarlos y entender sus necesidades',
          features: ['Seguimiento de citas', 'Notificaciones', 'Saludos cumpleaños'],
        },
        {
          title: 'Reportes',
          image: <ImageReporte />,
          description:
            'Más allá de generar información está el analizarla. Te brindamos los principales reportes para la toma de decisiones de crecimiento',
          features: ['Reportes de ventas', 'Stock valorizado', 'Cuadre de Caja', 'Venta neta', 'Comisiones', 'Y más...'],
        },
      ],
    },
    prices: {
      title: 'Precios',
      description:
        'Escoge el plan que mejor se acomode a tus necesidades y puedes solicitar una demo gratis. Cancela cuando quieras.',
      table: {
        characteristics: {
          title: 'CARACTERISTICAS',
          monthlypayment: 'MENSUALIDAD*',
          specialOffer: 'Por Promoción',
          listCharacter: [
            { title: 'Usuarios', basic: 2, standar: 4, advanced: '-', children: [] },
            { title: 'Pacientes', basic: true, standar: true, advanced: true, children: [] },
            { title: 'Chat', basic: null, standar: true, advanced: true, children: [] },
            {
              title: 'Citas',
              children: [
                { description: 'Recursos / Salas', basic: null, standar: true, advanced: true },
                { description: 'Recordatorios', basic: true, standar: true, advanced: true },
                { description: 'Citas en línea', basic: null, standar: null, advanced: true },
                {
                  description: 'Pasarela de pago Culqui',
                  basic: null,
                  standar: null,
                  advanced: true,
                },
              ],
            },
            {
              title: 'Ventas',
              children: [
                { description: 'Cobranza', basic: true, standar: true, advanced: true },
                { description: 'Pagos parciales', basic: true, standar: true, advanced: true },
                {
                  description: 'Apertura/Cierre Cajas',
                  basic: null,
                  standar: true,
                  advanced: true,
                },
                {
                  description: 'Farmacia',
                  basic: null,
                  standar: true,
                  advanced: true,
                },
                {
                  description: 'Facturación electrónica',
                  basic: false,
                  standar: false,
                  advanced: true,
                },
                {
                  description: 'Libro de Reclamaciónes',
                  basic: null,
                  standar: null,
                  advanced: true,
                },
              ],
            },
            {
              title: 'Inventarios',
              children: [
                {
                  description: 'Registro de artículos/servicios',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                { description: 'Fórmulas magistrales', basic: true, standar: true, advanced: true },
                {
                  description: 'Ingreso/Salida de Mercadería',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                {
                  description: 'Transferencias entre almacenes',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
              ],
            },
            {
              title: 'Reportes',
              children: [
                {
                  description: 'Estándares',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                { description: 'Ventas por servicio', basic: true, standar: true, advanced: true },
                {
                  description: 'Productividad por especialista',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                {
                  description: 'Dashboard',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                {
                  description: 'Cubos de datos',
                  basic: null,
                  standar: false,
                  advanced: true,
                },
              ],
            },
            {
              title: 'Infraestructura',
              children: [
                {
                  description: 'Base de datos dedicada',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                { description: 'Almacenamiento', basic: '2GB', standar: '10GB', advanced: '>10GB' },
              ],
            },
            {
              title: 'Soporte',
              children: [
                {
                  description: 'Vía Whatsapp / Correo / Acceso remoto',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
                {
                  description: 'Capacitaciones remotas',
                  basic: '1 x Año',
                  standar: '1 x Año',
                  advanced: 'A coordinar',
                },
                {
                  description: 'Copias de seguridad BD',
                  basic: true,
                  standar: true,
                  advanced: true,
                },
              ],
            },
          ],
        },
        plans: {
          basic: { title: 'BASICO', price: 'S/ 300.00', offer: 'S/ 250.00' },
          standar: { title: 'ESTANDAR', price: 'S/ 550.00', offer: 'S/ 420.00' },
          advanced: { title: 'AVANZADO', price: '', offer: 'Consultar' },
        },
        legend: {
          important: '* Precios incluyen IGV y el costo de implementación dependerá de las adaptaciones y migración.',
          include: 'Incluido',
          optional: 'Opcional (Costo adicional)',
        },
      },
    },
  },
};
