/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, DOMAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends DOMAttributes<SVGElement> {
  size?: number;
  color?: string | string[];
  style?: CSSProperties;
  className?: string;
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconChecked: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 96C281.6 96 96 281.6 96 512s185.6 416 416 416 416-185.6 416-416c0-44.8-6.016-89.408-22.016-131.008L854.016 432c6.4 25.6 10.016 51.2 10.016 80 0 195.2-156.8 352-352 352S160 707.2 160 512 316.8 160 512 160c96 0 182.208 38.208 243.008 99.008L800 214.016C726.4 140.416 624 96 512 96z m360.992 136.992L512 594.016l-136.992-137.024-46.016 46.016 160 160 23.008 21.984 23.008-21.984 384-384L872.96 232.96z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconChecked.defaultProps = {
  size: 18,
};

export default IconChecked;
