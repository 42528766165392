/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, DOMAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends DOMAttributes<SVGElement> {
  size?: number;
  color?: string | string[];
  style?: CSSProperties;
  className?: string;
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconPlay: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 896C299.936 896 128 724.064 128 512S299.936 128 512 128s384 171.936 384 384-171.936 384-384 384m0-832C264.96 64 64 264.96 64 512s200.96 448 448 448 448-200.96 448-448S759.04 64 512 64"
        fill={getIconColor(color, 0, '#181818')}
      />
      <path
        d="M416 345.728L704 512l-288 166.272V345.728zM800 512c0-11.84-6.72-21.728-16.256-27.264l0.256-0.448-384-221.728-0.256 0.48A30.912 30.912 0 0 0 384 258.304a32 32 0 0 0-32 32v443.392a32 32 0 0 0 32 32 30.912 30.912 0 0 0 15.744-4.736l0.256 0.448 384-221.696-0.256-0.448A31.584 31.584 0 0 0 800 512z"
        fill={getIconColor(color, 1, '#181818')}
      />
    </svg>
  );
};

IconPlay.defaultProps = {
  size: 18,
};

export default IconPlay;
