/* tslint:disable */
/* eslint-disable */

import React, { CSSProperties, DOMAttributes, FunctionComponent } from 'react';
import { getIconColor } from './helper';

interface Props extends DOMAttributes<SVGElement> {
  size?: number;
  color?: string | string[];
  style?: CSSProperties;
  className?: string;
}

const DEFAULT_STYLE: CSSProperties = {
  display: 'block',
};

const IconPhone: FunctionComponent<Props> = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M832 708.096v120.864C494.816 798.4 225.6 529.184 195.04 192h196.544l27.136 101.312-23.776 6.368-61.728 16.544c5.12 20.896 11.456 41.28 19.072 61.12a516.032 516.032 0 0 0 297.632 296.832c19.872 7.552 40.256 13.888 61.184 18.976l16.544-61.76 6.688-24.96L832 632.64v75.456z m42.4-129.984l0.096-0.32-123.616-33.12 0.032-0.128-30.912-8.32a31.936 31.936 0 0 0-33.568 11.36 32.32 32.32 0 0 0-5.696 11.392l-8.256 30.944h0.032l-5.984 22.24a451.84 451.84 0 0 1-252.224-251.392l20.992-5.664 0.032 0.096 30.912-8.32a32 32 0 0 0 22.624-39.168l-41.408-154.56-0.8 0.224A31.52 31.52 0 0 0 416 128H160.832c-18.496 0-32.864 15.616-31.968 34.112 0.512 10.016 1.184 19.968 2.016 29.888C161.792 564.48 459.52 862.208 832 893.12c9.92 0.832 19.872 1.504 29.888 2.016a32.224 32.224 0 0 0 34.112-32V608a31.68 31.68 0 0 0-21.6-29.856z"
        fill={getIconColor(color, 0, '#181818')}
      />
    </svg>
  );
};

IconPhone.defaultProps = {
  size: 18,
};

export default IconPhone;
